* {
    box-sizing: border-box;
}

/* Typography */

html {
    background: #e6eaf5;
    font-size: 62.5%;
    line-height: 1.15;
}

body {
    color: #333;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    line-height: 1.5;
    margin: 0;
}

h1 {
    color: #111;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

h1 {
    font-size: 2rem;
}

p {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.sr-only {
    visibility: hidden;
}

/* Links */

a {
    color: #333;
    text-decoration: underline;
    transition: 0.2s;
}

a:hover {
    color: #999;
}

/* Icons */

.icon {
    fill: none;
    height: 2.5rem;
    stroke-width: 6px;
    stroke: #333;
    transition: 0.2s;
    width: 2.5rem;
}

.icon:hover {
    stroke: #999;
}

/* Buttons */

.btn {
    border-radius: 5px;
    border: 0;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 1rem 0;
    padding: 1rem 3rem;
    text-align: center;
    text-transform: uppercase;
    transition: 0.2s;
    white-space: nowrap;
}

.btn--primary {
    background: #222;
    color: #fff;
}

.btn--primary:hover {
    background: #555;
}

.btn--secondary {
    background: #aaa;
}

.btn--secondary:hover {
    background: #ccc;
}

/* Navbar & Header */

.header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2rem 5rem 5rem;
    text-align: center;
}

.header__title {
    text-decoration: none;
    z-index: 9;
}

.navbar {
    background-color: hsla(224deg 43% 93% / 0.95);
    height: 100%;
    inset: 0 0 50% 0;
    list-style: none;
    margin: 0;
    opacity: 1;
    padding-top: 10rem;
    padding: 0;
    position: fixed;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    width: 100%;
    z-index: 8;
}

.navbar[data-visible="true"] {
    transform: translateX(0);
}

.navbar__item {
    padding: 1rem 0;
}

.navbar__item img {
    max-width: 2rem;
}

.navbar__item>a {
    text-decoration: none;
    transition: 0.2s;
}

.navbar__item:first-child {
    margin-top: 10rem;
}

.navbar--active {
    text-decoration: underline;
    text-underline-offset: 3px;
}

.hamburger {
    background: none;
    border: 0;
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    z-index: 9;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.hamburger__bar {
    background-color: #111;
    border-radius: 2px;
    display: block;
    height: 3px;
    margin: 4px -4.5px;
    padding: 0;
    transition: 0.3s ease-in-out;
    width: 25px;
}

.hamburger[aria-expanded="true"] .hamburger__bar:nth-child(2) {
    opacity: 0;
}

.hamburger[aria-expanded="true"] .hamburger__bar:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
}

.hamburger[aria-expanded="true"] .hamburger__bar:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
}

@media screen and (min-width: 768px) {
    .navbar {
        display: flex;
        padding-top: 0.5rem;
        position: relative;
        transform: translateX(0);
    }

    .hamburger {
        display: none;
    }

    .navbar__list {
        display: flex;
        width: auto;
    }

    .navbar__item {
        padding: 1rem;
    }

    .navbar__item:first-child {
        margin-top: 0;
    }
}

/* Home Page */

.home-text {
    font-size: 1.6rem;
    margin: 0 5rem;
    padding-bottom: 2rem;
    text-align: center;
}

.home-gallery {
    align-content: center;
    display: grid;
    gap: 10px;
    justify-content: center;
    padding: 1rem 5rem;
}

.home-gallery .home-gallery__img {
    border-radius: 5px;
    object-fit: cover;
    width: 100%;
}

.home-gallery__img {
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .home-gallery {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(6, 1fr);
    }
}

@media screen and (min-width: 1024px) {
    .home-gallery {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }
}

/* Forms */

.form {
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    width: 65%;
}

.form__input,
.form__textarea {
    border-radius: 5px;
    border: 1px solid #333;
    color: #111;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    outline: 0;
    padding: 1rem 1rem;
}

.form__textarea {
    resize: vertical;
}

::placeholder {
    color: #777;
}

.form__btn {
    margin: 0.25rem auto;
    width: fit-content;
}

.form__label {
    cursor: pointer;
    margin-bottom: 0.25rem;
}

.form__label::after {
    content: '*';
}

@media screen and (min-width: 768px) {
    .form {
        width: 40%;
    }
}

/* Lightbox */

#lightboxBg {
    backdrop-filter: blur(1rem);
    display: none;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 7;
}

#lightboxBg.active {
    display: grid;
    place-items: center;
}

#lightboxImg {
    border-radius: 5px;
    border: 0.5rem solid #fff;
    box-shadow: 5px 5px 20px #000;
    max-height: 90%;
    max-width: 90%;
    position: fixed;
    top: 15%;
}

.lb-close {
    background-color: #e6eaf5;
    border-radius: 5px;
    cursor: pointer;
    height: 3rem;
    opacity: 90%;
    position: fixed;
    right: 50%;
    top: 75%;
    width: 3rem;
    z-index: 8;
}

.lb-close[data-visible="false"] {
    display: none;
}

.lb-close[data-visible="true"] {
    display: block;
}

@media screen and (min-width: 768px) {
    #lightboxImg {
        border: 1rem solid #fff;
        max-height: 75%;
        max-width: 75%;
    }

    .lb-close {
        top: 85%;
    }
}

@media screen and (min-width: 1024px) {
    .lb-close {
        top: 92%;
    }
}